/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store_front from "../../assets/images/store_front_asset.svg";
import disabled_store_front from "../../assets/images/disabledStoreFront.svg";
import NoQ_logo from "../../assets/icons/NO-Q_logo.png";

import Loader from "../../common/Loader";
import { vendorActions } from "../../redux/actions";
import { onScroll } from "../../helpers/infiniteScroll";
import PageFrame from "../../common/PageFrame";
import AddressInput from "../../common/InputFields/Addressinput";
import CreateStore from "../../common/CreateStore";

const Dashboard = () => {
    const limit = 10;
    const [addStore, setAddStore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [vendorStores, setVendorStores] = useState([]);
    const [vendors, setVendors] = useState({});
    const [addStoreData, setAddStoreData] = useState({
        name: "",
        services: "",
    });
    const [storeLimit, setStoreLimit] = useState(limit);
    const [storePagination, setStorePagination] = useState({
        current: "",
        number_of_pages: "",
    });
    const [address, setAddress] = useState({});
    const [loadMoreStores, setLoadMoreStores] = useState(false);

    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");

    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        // isFetchingVendorStore,
        isFetchedVendorStoreSuccess,
        isAddingVendorStore,
        hasAddedVendorStoreSuccess,
        // isFetchingVendors,
        isFetchedVendorsSuccess,
    } = useSelector((state) => state.vendor);

    const container = document.getElementById("scrollContainer");

    const handleChange = (name, value) => {
        setAddStoreData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onStoreContainerScroll = useCallback(
        () =>
            onScroll(container, storePagination?.current, storePagination?.number_of_pages, () => {
                setStoreLimit(() => storeLimit + limit);
                setLoadMoreStores(true);
            }),
        [container, storePagination]
    );

    useEffect(() => {
        if (userObj?.token) {
            // setIsLoading(true);
            dispatch(vendorActions.getVendors(userObj.token));
        }
    }, [dispatch, userObj?.token]);

    useEffect(() => {
        if (isFetchedVendorsSuccess) {
            setVendors(isFetchedVendorsSuccess?.[0]);
            sessionStorage.setItem("vendorInfo", JSON.stringify(isFetchedVendorsSuccess?.[0]));
            // setAddStore(false);
            // setAddStoreData({ name: "", services: "" });
            // setAddress({});

            dispatch(vendorActions.getVendorStores(isFetchedVendorsSuccess?.[0]?.id, storeLimit));
        }
    }, [dispatch, isFetchedVendorsSuccess, hasAddedVendorStoreSuccess, storeLimit]);

    useEffect(() => {
        if (isFetchedVendorStoreSuccess) {
            setIsLoading(false);
            setVendorStores(isFetchedVendorStoreSuccess.data);
            setLoadMoreStores(false);
            setStorePagination({
                current: isFetchedVendorStoreSuccess?.meta?.current_page,
                number_of_pages: isFetchedVendorStoreSuccess?.meta?.last_page,
            });
        }
    }, [isFetchedVendorStoreSuccess]);

    useEffect(() => {
        container?.addEventListener("scroll", onStoreContainerScroll);
        return () => {
            container?.removeEventListener("scroll", onStoreContainerScroll);
        };
    }, [container, onStoreContainerScroll]);

    return (
        <PageFrame
            isLoading={isLoading && !loadMoreStores}
            frameLinks={[{ text: "Dashboard", url: "#", isActive: true }]}
            childrenContainerClass={`p-[30px] bg-white shadow-lg border rounded-lg min-h-[440px] w-full overflow-y-auto max-sm:shadow-none max-sm:border-none`}
        >
            <div

            // className="p-[30px] bg-white shadow-lg border rounded-lg h-[440px] w-full overflow-y-auto"
            >
                {!addStore ? (
                    <>
                        <div className="flex flex-wrap flex-row gap-[45px] pb-4 max-lg:justify-center">
                            <div
                                className="h-[140px] w-[230px] mb-3 bg-white"
                                onClick={() => {
                                    setAddStore(true);
                                }}
                            >
                                <div className="w-full h-full flex items-center justify-center border shadow-md cursor-pointer rounded-[10px]">
                                    <span className="material-icons text-6xl">add</span>
                                </div>
                                <p className="text-sm mt-3">Add New Storefront</p>
                            </div>
                            {vendorStores?.map((store, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="h-[140px] w-[230px] mb-3 bg-white"
                                        onClick={() => navigate(`/dashboard/store/${store?.id}`)}
                                    >
                                        <img
                                            className="w-full h-full object-contain p-2 flex items-center justify-center border shadow-md cursor-pointer rounded-[5px]"
                                            src={
                                                store?.status?.toLowerCase() !== "published"
                                                    ? disabled_store_front
                                                    : vendors?.[0]?.logo_url || store_front
                                            }
                                            // width={"150px"}
                                            // height={"150px"}
                                            alt="store logo"
                                        />

                                        <p className="text-sm mt-3">
                                            {store.name}{" "}
                                            {store?.status?.toLowerCase() !== "published" &&
                                                "(Inactive)"}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        {loadMoreStores && (
                            <div className="flex my-4 justify-center">
                                <Loader color="dark-blue" />
                            </div>
                        )}
                        {storePagination?.current === storePagination?.number_of_pages &&
                            vendorStores?.length > 0 && (
                                <div className="flex my-4 justify-center text-sm font-semibold">
                                    End of list
                                </div>
                            )}
                    </>
                ) : (
                    <CreateStore setAddStore={setAddStore} />
                )}
            </div>
        </PageFrame>
    );
};

export default Dashboard;
