import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import stepIcon from "../../assets/icons/login_step_icon.svg";
import { authActions } from "../../redux/actions";
import "./styles.css";
import NoQ_logo from "../../assets/icons/NO-Q_logo.png";
import LandingPage from "../LandingPage";

const Login = () => {
    const [show, setShow] = useState(false);
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
        rememberMe: false,
    });
    const [forgetEmail, setForgetEmail] = useState("");
    const [isForgotPassword, setIsForgotPassword] = useState(false);

    const [isSignUp, setIsSignUp] = useState(false);

    const dispatch = useDispatch();
    const { isLoggingIn, isRequestingPasswordReset, isRequestingPasswordResetSuccess } =
        useSelector((state) => state.auth);

    const handleClick = () => {
        setShow(!show);
    };

    const handleLogin = () => {
        dispatch(
            authActions.loginUser(
                { email: loginInfo.email, password: loginInfo.password },
                loginInfo.rememberMe
            )
        );
    };

    const handleRequestPasswordReset = () => {
        dispatch(authActions.resquestPasswordReset({ email: forgetEmail }));
    };

    // useEffect(() => {
    //     if (Boolean(isRequestingPasswordResetSuccess)) {
    //         localStorage.setItem("resetPasswordEmail", JSON.stringify({ email: forgetEmail }));
    //     }
    // }, [isRequestingPasswordResetSuccess]);

    return (
        <div className="login-container font-sans">
            <div className="card">
                <div className="left">
                    <div className="left-content">
                        <div>
                            <a href="/">
                                <p className="left-title">No-Q</p>
                            </a>
                        </div>
                        <div className="login-steps">
                            <div>
                                <div className="step-icon">
                                    <img src={stepIcon} alt="step icon" />
                                </div>
                                <p className="login-step">Step 1</p>
                                <p className="step-desc">
                                    Create an Account with your Business and personal details.
                                </p>
                            </div>
                            <div>
                                <div className="step-icon">
                                    <img src={stepIcon} alt="step icon" />
                                </div>
                                <p className="login-step">Step 2</p>
                                <p className="step-desc">
                                    Log into your account and set up one or multiple store fronts
                                    per event.
                                </p>
                            </div>
                            <div>
                                <div className="step-icon">
                                    <img src={stepIcon} alt="step icon" />
                                </div>
                                <p className="login-step">Step 3</p>
                                <p className="step-desc">
                                    Share your storefront URL or print a QR Code for event goers to
                                    shop online. No queues, More sales.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right">
                    {isForgotPassword ? (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleRequestPasswordReset();
                            }}
                        >
                            <div
                                className="flex items-center space-x-1.5 w-fit cursor-pointer mb-6"
                                onClick={() => setIsForgotPassword(false)}
                            >
                                <i className="ri-arrow-left-line"></i>
                                <span className="text-sm font-semibold">Back</span>
                            </div>

                            <div className="header">
                                <div className="">
                                    <Link className="" to={"/"}>
                                        <img
                                            src={NoQ_logo}
                                            alt=""
                                            width={"100px"}
                                            height={"100px"}
                                        />
                                    </Link>

                                    {/* <p className="font-bold text-xl ">Vendor Login</p>
                                <p className="text-sm ">Please login to your no-Q Vendor Account</p> */}
                                </div>
                                <p className="font-bold text-xl ">Reset Password</p>
                                <p className="text-sm ">
                                    Please fill in your account's email address to receive a reset
                                    link
                                </p>
                            </div>
                            <div>
                                <div className="mb-4">
                                    <input
                                        type={"text"}
                                        placeholder="Enter your email address"
                                        className="input-field"
                                        value={forgetEmail}
                                        required
                                        onChange={(e) => {
                                            setForgetEmail(e.target.value);
                                        }}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    disabled={isRequestingPasswordReset}
                                    className="bg-[#DE0000] w-full text-base text-white py-3 rounded"
                                    id="loginButton"
                                >
                                    {isRequestingPasswordReset ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </form>
                    ) : (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleLogin();
                            }}
                            className="box"
                        >
                            <div className="header">
                                <Link className="" to={"/"}>
                                    <img src={NoQ_logo} alt="" width={"100px"} height={"100px"} />
                                </Link>

                                <p className="font-bold text-xl ">Vendor Login</p>
                                <p className="text-sm ">Please login to your NO-Q Vendor Account</p>
                            </div>
                            <div>
                                <div className="mb-4">
                                    <p className="text-base mb-1">Email</p>
                                    <input
                                        type={"text"}
                                        placeholder="Enter your email"
                                        className="input-field"
                                        required
                                        onChange={(e) => {
                                            setLoginInfo((prev) => {
                                                return {
                                                    ...prev,
                                                    email: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className="text-base mb-1">Password</p>
                                    <div className="flex relative items-center">
                                        <input
                                            type={show ? "text" : "password"}
                                            placeholder="Password"
                                            className="input-field"
                                            onChange={(e) => {
                                                setLoginInfo((prev) => {
                                                    return {
                                                        ...prev,
                                                        password: e.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                        <div className="h-full absolute right-0 flex items-center pr-4">
                                            {!show ? (
                                                <i
                                                    onClick={handleClick}
                                                    className="ri-eye-close-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                                ></i>
                                            ) : (
                                                <i
                                                    onClick={handleClick}
                                                    className="ri-eye-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                                ></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex mt-2 justify-between">
                                <div className="flex gap-1 ">
                                    <input
                                        type="checkbox"
                                        checked={loginInfo.rememberMe}
                                        onChange={() =>
                                            setLoginInfo((prev) => ({
                                                ...prev,
                                                rememberMe: !prev.rememberMe,
                                            }))
                                        }
                                    />
                                    <label
                                        className="text-sm cursor-pointer"
                                        onClick={() => {
                                            setLoginInfo((prev) => {
                                                return {
                                                    ...prev,
                                                    rememberMe: !prev.rememberMe,
                                                };
                                            });
                                        }}
                                    >
                                        {" "}
                                        Remember Me
                                    </label>
                                </div>
                                <p
                                    className="cursor-pointer text-sm"
                                    onClick={() => setIsForgotPassword(true)}
                                >
                                    Forgot Password?
                                </p>
                            </div>
                            <button
                                type="submit"
                                disabled={isLoggingIn}
                                className="bg-[#DE0000] mt-14 w-full text-base text-white py-3 rounded"
                                id="loginButton"
                            >
                                {isLoggingIn ? "Logging in..." : "Login"}
                            </button>
                        </form>
                    )}

                    <div className="signup">
                        Don't have an account?{" "}
                        <Link className="underline" to={"/register"}>
                            Create one here
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
