import { useState } from "react";
import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
  

    <div className="border-b border-gray-300 ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full py-4 text-left text-lg font-medium text-gray-800 hover:text-blue-600 focus:outline-none"
      >
        {question}
        <span className={`transform transition-transform ${isOpen ? "rotate-180" : "rotate-0"}`}>
          ▼
        </span>
      </button>
      {isOpen && (
        <div className="px-4 py-2 text-gray-600 text-sm">
          {answer}
        </div>
      )}
    </div>    

  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "What is No-Q?",
      answer: "No-Q is an online marketplace for vendors at home or vendors at outdoor events, it allows customers and event attendees to browse, order, and pay for products or services from nearby vendors directly from their phones.",
    },
    {
      question: "How does No-Q work?",
      answer: "Vendors list their products or services on No-Q, and attendees can use their phones to place orders, pay online, and skip the hassle of waiting in long lines at events.",
    },
    {
      question: "Who can use No-Q",
      answer: "No-Q is designed for neighborhood businesses, community vendors or event vendors, including food trucks, merchandise sellers, and service providers, as well as event attendees and organizers looking for a more streamlined experience.",
    },
    {
        question: "How do I sign up as a vendor?",
        answer: "You can sign up by joining the waitlist on our website. Once registered, you’ll get access to tools to set up your online store.",
      },

      {
        question: "Are there fees for vendors?",
        answer: " Yes, No-Q charges a small fee for sales on the platform. Contact us for more details about pricing.",
      },

      {
        question: " How do I use No-Q as a customer/attendee?",
        answer: "You can scan the vendor's QR code to skip the line or visit the event's No-Q page to browse vendors nearby, place orders, and make payments—all from your phone. ",
      },

      {
        question: "Do I need to download an app for No-Q? ",
        answer: "NO, No-Q is available on your web browser and is mobile-friendly, so you can use it without needing to download anything. ",
      },

      {
        question: "How can No-Q improve my event? ",
        answer: "No-Q enhances the attendee experience by reducing queues, increasing vendor sales, and providing insights into vendor performance. ",
      },

     





  ];

  return (
    <div className="font-sans">
    <div className="max-w-2xl min-h-screen mx-auto h-full my-8 max-sm:mx-5 ">
        <Navbar/>

        <h1 className="text-4xl font-bold mt-32 mb-5 max-sm:text-center">
        Frequently Asked Questions (F.A.Q.)
    </h1>

      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
          </div>



<Footer/>

</div>
  );
};

export default FAQ;
