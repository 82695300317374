import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../redux/actions";
import FormOne from "./components/FormOne";
import FormTwo from "./components/FormTwo";
import "./styles.css";
import NoQ_logo from "../../assets/icons/NO-Q_logo.png";
import { Link } from "react-router-dom";
import stepIcon from "../../assets/icons/login_step_icon.svg";
import Docs from "../Docs";

const Signup = () => {
    const [currentPage, setCurrentPage] = useState("createAccount");

    const showCreateAccount = () => setCurrentPage("createAccount");
    const showSignUpForm = () => setCurrentPage("signUpForm");

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: "",
        type: "",
        email: "",
        phone_number: "",
        street: "",
        city: "",
        postal_code: "",
        services: "",
        logo: null,
        user: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirm_password: "",
            phone_number: "",
        },
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedInSuccess, isSignUpInSuccess, isSigningUp } = useSelector(
        (state) => state.auth
    );

    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const [user] = useState(noqUser);

    const handleNext = useCallback(() => {
        if (activeStep === 1) {
            const formBody = new FormData();
            Object.keys(formData).forEach((key) => {
                if (typeof formData[key] === "object" && key !== "logo") {
                    Object.keys(formData[key]).forEach((nestedKey) => {
                        formBody.append(`${key}[${nestedKey}]`, formData[key][nestedKey]);
                    });
                } else {
                    if (formData[key]) {
                        formBody.append(key, formData[key]);
                    }
                }
            });

            const { user, ...rest } = formData;
            const { confirm_password, ...others } = user;
            // formBody.append("user", { ...others });
            // return dispatch(authActions.signup({ ...rest, user: { ...others } }));
            // console.log("formBody", Array.from(formBody.entries()), formData);

            return dispatch(authActions.signup(formBody));
        }
        setActiveStep((prevState) => prevState + 1);
    }, [activeStep, dispatch, formData]);
    // console.log("formData", formData);
    const handleBack = useCallback(() => {
        if (activeStep === 1) {
            return setActiveStep((prevState) => prevState - 1);
        }
        return navigate("/login");
    }, [activeStep, navigate]);

    const displayActiveForm = () => {
        switch (activeStep) {
            case 0:
                return (
                    <FormOne
                        formData={formData}
                        setFormData={setFormData}
                        handleBack={handleBack}
                    />
                );

            case 1:
                return (
                    <FormTwo
                        formData={formData}
                        setFormData={setFormData}
                        handleBack={handleBack}
                        isSigningUp={isSigningUp}
                    />
                );

            default:
                return (
                    <FormOne
                        formData={formData}
                        setFormData={setFormData}
                        handleBack={handleBack}
                    />
                );
        }
    };

    // useEffect(() => {
    //     if (isSignUpInSuccess || user || isLoggedInSuccess) {
    //         navigate("/dashboard");
    //     }
    // }, [navigate, isSignUpInSuccess, user, isLoggedInSuccess]);

    useEffect(() => {
        if (isSignUpInSuccess) {
            navigate("/login");
            dispatch(authActions.resetSignUpSuccess());
        }
    }, [dispatch, navigate, isSignUpInSuccess]);

    return (
        <div className="signup-container font-sans">
            {currentPage === "createAccount" && (
                <div className="card">
                    <div className="left">
                        <div className="left-content max-sm:hidden">
                            <div>
                                <a href="/">
                                    <p className="left-title">No-Q</p>
                                </a>
                            </div>
                            <div className="login-steps">
                                <div>
                                    <div className="step-icon">
                                        <img src={stepIcon} alt="step icon" />
                                    </div>
                                    <p className="login-step">Step 1</p>
                                    <p className="step-desc">
                                        Create an Account with your Business and personal details.
                                    </p>
                                </div>
                                <div>
                                    <div className="step-icon">
                                        <img src={stepIcon} alt="step icon" />
                                    </div>
                                    <p className="login-step">Step 2</p>
                                    <p className="step-desc">
                                        Log into your account and set up one or multiple store
                                        fronts per event.
                                    </p>
                                </div>
                                <div>
                                    <div className="step-icon">
                                        <img src={stepIcon} alt="step icon" />
                                    </div>
                                    <p className="login-step">Step 3</p>
                                    <p className="step-desc">
                                        Share your storefront URL or print a QR Code for event goers
                                        to shop online. No queues, More sales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="right">
                        <div className="header">
                            <Link className="" to={"/"}>
                                <img src={NoQ_logo} alt="logo" width={"100px"} height={"100px"} />
                            </Link>

                            <button
                                onClick={showSignUpForm}
                                className={`bg-[#DE0000] w-full text-base text-white py-3 rounded ${
                                    currentPage === "signUpForm" ? "active" : ""
                                } `}
                            >
                                Create Account
                            </button>

                            {/* <p className="text-start text-sm w-[60%] mt-3 max-sm:w-full">
                        By signing up, you agree to the <Link className="underline" to={"/Docs"}>Terms of Service</Link> and <Link className="underline" to={"/Docs#privacy"}>Privacy Policy</Link>, including <Link className="underline" to={"/Docs#cookies"}>Cookie Use</Link>.
                        </p> */}

                            <p className="text-start text-sm w-[60%] mt-3 max-sm:w-full">
                                By Creating an account, you agree to the{" "}
                                <a
                                    href={"/Docs"}
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of Service
                                </a>{" "}
                                and{" "}
                                <a
                                    href={"/Docs#privacy"}
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                , including{" "}
                                <a
                                    href={"/Docs#cookies"}
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Cookie Use
                                </a>
                                .
                            </p>

                            <p className="absolute bottom-[12px] text-center text-sm">
                                Have an account already?{" "}
                                <Link className="underline" to={"/login"}>
                                    {" "}
                                    Log in{" "}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className="top" /> */}
            {currentPage === "signUpForm" && (
                <div className="form-container flex flex-row h-[100%] bg-white">
                    {/* <div className={`flex steps ${activeStep === 1 && "step-two"}`} id="signUpSteps">
                    <div className={`step ${activeStep === 0 ? "active-step" : ""}`}>
                        <div className="step-number">
                            <p>1</p>
                        </div>
                        <p>Your Profile</p>
                    </div>
                    <div className={`step`}>
                        <div className="step-number">
                            <p>2</p>
                        </div>
                        <p>Business/Store Information</p>
                    </div>
                 </div> */}
                    {/* LEFT SECTION */}
                    <div className="h-[100%] w-[40%] border flex items-center justify-center text-white bg-[#181818] max-sm:hidden">
                        <div className="left-content">
                            <div>
                                <a href="/">
                                    <p className="left-title">No-Q</p>
                                </a>
                            </div>
                            <div className="login-steps">
                                <div>
                                    <div className="step-icon">
                                        <img src={stepIcon} alt="step icon" />
                                    </div>
                                    <p className="login-step">Step 1</p>
                                    <p className="step-desc">
                                        Create an Account with your Business and personal details.
                                    </p>
                                </div>
                                <div>
                                    <div className="step-icon">
                                        <img src={stepIcon} alt="step icon" />
                                    </div>
                                    <p className="step">Step 2</p>
                                    <p className="step-desc">
                                        Log into your account and set up one or multiple store
                                        fronts per event.
                                    </p>
                                </div>
                                <div>
                                    <div className="step-icon">
                                        <img src={stepIcon} alt="step icon" />
                                    </div>
                                    <p className="step">Step 3</p>
                                    <p className="step-desc">
                                        Share your storefront URL or print a QR Code for event goers
                                        to shop online. No queues, More sales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* END OF LEFT SECTION */}
                    <div className="flex flex-col w-full justify-center items-center ">
                    <Link className="" to={"/"}>
                        <img src={NoQ_logo} className="w-[130px] h-[130px]"/>
                        </Link>
                        <div className="form-title-container ">
                            {activeStep === 0 && (
                                <>
                                    <h3 className="text-3xl font-bold h-fit">Create an Account</h3>
                                    <p className="hidden">
                                        Enter your personal details to create an account. You will{" "}
                                        <br />
                                        be able to enter your business information in the second
                                        step.
                                    </p>
                                </>
                            )}
                            {activeStep === 1 && (
                                <>
                                    <h3 className="text-3xl font-bold">Create an Account</h3>
                                    <p className="hidden">
                                        Please enter information about your Business.
                                    </p>
                                </>
                            )}
                        </div>

                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleNext();
                            }}
                        >
                            {displayActiveForm()}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;
