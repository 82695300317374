import React from 'react'

const getCurrentYear = () => {
    return new Date().getFullYear();
};

const Footer = () => {
  return (
    <div className='flex flex-row py-8 mt-auto justify-center w-full bg-black'>

<footer className="font-semibold text-white ">
                        {" "}
                        &copy; {getCurrentYear()} NO-Q. All rights reserved.{" "}
                    </footer>
    </div>
  )
}

export default Footer