import toast from "react-hot-toast";
import { vendorServices } from "../../services";
import { vendorTypes } from "../types";

const request = (type) => {
    return { type: type };
};

const success = (type, data) => {
    return { type: type, payload: data };
};

const failure = (type, error) => {
    return { type: type, payload: error ?? "" };
};

const getVendorStores = (vendorId, size) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_VENDOR_STORES_REQUEST));
        vendorServices.getVendorStores(vendorId, size).then((res) => {
            if (res?.success) {
                dispatch(success(vendorTypes.GET_VENDOR_STORES_SUCCESS, res));
            }
            if (res?.message) {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_VENDOR_STORES_FAILURE, res.message));
            }
        });
    };
};

const getVendors = (token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_VENDORS_REQUEST));
        vendorServices.getVendors(token).then((res) => {
            if (res?.success) {
                dispatch(success(vendorTypes.GET_VENDORS_SUCCESS, res?.data));
            }
            if (res?.message) {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_VENDORS_FAILURE, res.message));
            }
        });
    };
};

const addVendorStore = (vendorId, data, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.ADD_VENDOR_STORE_REQUEST));
        vendorServices.addVendorStore(vendorId, data, token).then((res) => {
            if (res?.data) {
                dispatch(success(vendorTypes.ADD_VENDOR_STORE_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.ADD_VENDOR_STORE_FAILURE, res.message));
            }
        });
    };
};

const updateVendorStore = (productId, data, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.UPDATE_VENDOR_STORE_REQUEST));
        vendorServices.updateVendorStore(productId, data, token).then((res) => {
            if (res?.data) {
                dispatch(success(vendorTypes.UPDATE_VENDOR_STORE_SUCCESS, res?.data));
                toast.success("Store updated");
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.UPDATE_VENDOR_STORE_FAILURE, res.message));
            }
        });
    };
};

const getStoreDetails = (storeId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_STORE_DETAIL_REQUEST));
        vendorServices.getAStore(storeId, token).then((res) => {
            if (res?.data) {
                dispatch(success(vendorTypes.GET_STORE_DETAIL_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_STORE_DETAIL_FAILURE, res.message));
            }
        });
    };
};

const getStoreProducts = (storeId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_STORE_PRODUCTS_REQUEST));
        vendorServices.getStoreProducts(storeId, token).then((res) => {
            if (res?.success) {
                dispatch(success(vendorTypes.GET_STORE_PRODUCTS_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_STORE_PRODUCTS_FAILURE, res.message));
            }
        });
    };
};

const getCategories = (vendorId, token, storeId) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_CATEGORIES_REQUEST));
        vendorServices.getCategories(vendorId, token, storeId).then((res) => {
            if (res?.success) {
                dispatch(success(vendorTypes.GET_CATEGORIES_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_CATEGORIES_FAILURE, res.message));
            }
        });
    };
};

const createCategory = (vendorId, storeId, token, data) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.CREATE_CATEGORY_REQUEST));
        vendorServices.createCategory(vendorId, storeId, token, data).then((res) => {
            if (res?.success || res?.data) {
                toast.success("Category Created!");
                dispatch(success(vendorTypes.CREATE_CATEGORY_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.CREATE_CATEGORY_FAILURE, res.message));
            }
        });
    };
};

const updateCategory = (vendorId, categoryId, token, data) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.UPDATE_CATEGORY_REQUEST));
        vendorServices.updateCategory(vendorId, categoryId, token, data).then((res) => {
            if (res?.success || res?.data) {
                toast.success("Category Updated!");
                dispatch(success(vendorTypes.UPDATE_CATEGORY_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.UPDATE_CATEGORY_FAILURE, res.message));
            }
        });
    };
};

const deleteCategory = (vendorId, categoryId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.DELETE_CATEGORY_REQUEST));
        vendorServices.deleteCategory(vendorId, categoryId, token).then((res) => {
            if (res.message) {
                toast.error(res.message);
                return dispatch(failure(vendorTypes.DELETE_CATEGORY_FAILURE, res.message));
            }
            toast.success("Deleted successfully");
            dispatch(success(vendorTypes.DELETE_CATEGORY_SUCCESS, true));
        });
    };
};

const getCategoryProducts = (categoryId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_CATEGORY_PRODUCTS_REQUEST));
        vendorServices.getCategoryProducts(categoryId, token).then((res) => {
            if (res?.success) {
                dispatch(success(vendorTypes.GET_CATEGORY_PRODUCTS_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_CATEGORY_PRODUCTS_FAILURE, res.message));
            }
        });
    };
};

const createProduct = (vendorId, productId, token, data) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.CREATE_PRODUCT_REQUEST));
        vendorServices.createProduct(vendorId, productId, token, data).then((res) => {
            if (res?.success || res?.data) {
                toast.success(productId ? "Product Updated" : "Product Created!");
                dispatch(success(vendorTypes.CREATE_PRODUCT_SUCCESS, res?.data));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.CREATE_PRODUCT_FAILURE, res.message));
            }
        });
    };
};

const getStoreOrders = (storeId, token, page) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_STORE_ORDERS_REQUEST));
        vendorServices.getStoreOrders(storeId, token, page).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.GET_STORE_ORDERS_SUCCESS, res));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_STORE_ORDERS_FAILURE, res.message));
            }
        });
    };
};

const getPendingStoreOrders = (storeId, token, page) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_PENDING_STORE_ORDERS_REQUEST));
        vendorServices.getPendingStoreOrders(storeId, token, page).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.GET_PENDING_STORE_ORDERS_SUCCESS, res));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_PENDING_STORE_ORDERS_FAILURE, res.message));
            }
        });
    };
};

const getCompletedStoreOrders = (storeId, token, page) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_COMPLETED_STORE_ORDERS_REQUEST));
        vendorServices.getCompletedStoreOrders(storeId, token, page).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.GET_COMPLETED_STORE_ORDERS_SUCCESS, res));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_COMPLETED_STORE_ORDERS_FAILURE, res.message));
            }
        });
    };
};

const getCancelledStoreOrders = (storeId, token, page) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_CANCELLED_STORE_ORDERS_REQUEST));
        vendorServices.getCancelledStoreOrders(storeId, token, page).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.GET_CANCELLED_STORE_ORDERS_SUCCESS, res));
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.GET_CANCELLED_STORE_ORDERS_FAILURE, res.message));
            }
        });
    };
};

const processOrder = (storeId, orderId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.PROCESS_ORDER_REQUEST));
        vendorServices.processOrder(storeId, orderId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.PROCESS_ORDER_SUCCESS, res?.data));
                toast.success("Order started");
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.PROCESS_ORDER_FAILURE, res.message));
            }
        });
    };
};

const markOrderAsProcessed = (storeId, orderId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.MARK_ORDER_AS_PROCESSED_REQUEST));
        vendorServices.markOrderAsProcessed(storeId, orderId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.MARK_ORDER_AS_PROCESSED_SUCCESS, res?.data));
                toast.success("Order completed");
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.MARK_ORDER_AS_PROCESSED_FAILURE, res.message));
            }
        });
    };
};

const markOrderAsDelivered = (storeId, orderId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.MARK_ORDER_AS_DELIVERED_REQUEST));
        vendorServices.markOrderAsDelivered(storeId, orderId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.MARK_ORDER_AS_DELIVERED_SUCCESS, res?.data));
                toast.success("Order delivered");
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.MARK_ORDER_AS_DELIVERED_FAILURE, res.message));
            }
        });
    };
};

const cancelOrder = (storeId, orderId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.CANCEL_ORDER_REQUEST));
        vendorServices.cancelOrder(storeId, orderId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.CANCEL_ORDER_SUCCESS, res?.data));
                toast.success("Order cancelled");
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.CANCEL_ORDER_FAILURE, res.message));
            }
        });
    };
};

const saveAllProducts = (vendorId, data, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.SAVE_ALL_PRODUCTS_REQUEST));
        vendorServices.saveAllProducts(vendorId, data, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.SAVE_ALL_PRODUCTS_SUCCESS, res?.data));
                toast.success("Product(s) saved successfully");
            } else {
                toast.error(res.message);
                dispatch(failure(vendorTypes.SAVE_ALL_PRODUCTS_FAILURE, res.message));
            }
        });
    };
};

const deleteProduct = (vendorId, productId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.DELETE_PRODUCT_REQUEST));
        vendorServices.deleteProduct(vendorId, productId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.DELETE_PRODUCT_SUCCESS, res?.data));
                toast.success("Product deleted successfully");
            } else {
                toast.error(res?.message || "");
                dispatch(failure(vendorTypes.DELETE_PRODUCT_FAILURE, res?.message));
            }
        });
    };
};

const publishStore = (storeId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.PUBLISH_STORE_REQUEST));
        vendorServices.publishStore(storeId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.PUBLISH_STORE_SUCCESS, res?.data));
                toast.success("Store published successfully");
            } else {
                toast.error(res?.message || "");
                dispatch(failure(vendorTypes.PUBLISH_STORE_FAILURE, res?.message));
            }
        });
    };
};

const unpublishStore = (storeId, token) => {
    return (dispatch) => {
        dispatch(request(vendorTypes.UNPUBLISH_STORE_REQUEST));
        vendorServices.unpublishStore(storeId, token).then((res) => {
            if (res?.success || res?.data) {
                dispatch(success(vendorTypes.UNPUBLISH_STORE_SUCCESS, res?.data));
                toast.success("Store unpublished successfully");
            } else {
                toast.error(res?.message || "");
                dispatch(failure(vendorTypes.UNPUBLISH_STORE_FAILURE, res?.message));
            }
        });
    };
};

const resetPublishStoreSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.PUBLISH_STORE_SUCCESS, null));
    };
};

const resetUnpublishStoreSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.UNPUBLISH_STORE_SUCCESS, null));
    };
};

const resetDeleteProductSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.DELETE_PRODUCT_SUCCESS, null));
    };
};

const resetSaveAllProducts = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.SAVE_ALL_PRODUCTS_SUCCESS, null));
    };
};

const resetAddVendorStore = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.ADD_VENDOR_STORE_SUCCESS, null));
    };
};

const resetUpdateVendorStore = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.UPDATE_VENDOR_STORE_SUCCESS, null));
    };
};

const resetCancelOrderSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.CANCEL_ORDER_SUCCESS, null));
    };
};

const resetMarkOrderAsDeliveredSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.MARK_ORDER_AS_DELIVERED_SUCCESS, null));
    };
};

const resetMarkOrderAsProcessedSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.MARK_ORDER_AS_PROCESSED_SUCCESS, null));
    };
};

const resetProcessOrderSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.PROCESS_ORDER_SUCCESS, null));
    };
};

const resetGetStoreOrdersSuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.GET_STORE_ORDERS_SUCCESS, null));
    };
};

const resetCreateCategorySuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.CREATE_CATEGORY_SUCCESS, null));
    };
};

const resetDeleteCategorySuccess = () => {
    return (dispatch) => {
        dispatch(request(vendorTypes.DELETE_CATEGORY_SUCCESS, null));
    };
};

export const vendorActions = {
    getVendorStores,
    getVendors,
    addVendorStore,
    updateVendorStore,
    resetAddVendorStore,
    resetUpdateVendorStore,
    getStoreDetails,
    getStoreProducts,
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    getCategoryProducts,
    createProduct,
    resetCreateCategorySuccess,
    resetDeleteCategorySuccess,
    getStoreOrders,
    getPendingStoreOrders,
    getCompletedStoreOrders,
    getCancelledStoreOrders,
    resetGetStoreOrdersSuccess,
    processOrder,
    resetProcessOrderSuccess,
    markOrderAsProcessed,
    resetMarkOrderAsProcessedSuccess,
    markOrderAsDelivered,
    resetMarkOrderAsDeliveredSuccess,
    cancelOrder,
    resetCancelOrderSuccess,
    saveAllProducts,
    resetSaveAllProducts,
    deleteProduct,
    resetDeleteProductSuccess,
    publishStore,
    resetPublishStoreSuccess,
    unpublishStore,
    resetUnpublishStoreSuccess,
};
